var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.flag)?_c('div',{staticClass:"order-mask"},[_c('div',{staticClass:"mask-main"},[_c('div',{staticClass:"mask-nav"},[_vm._m(0),_c('i',{staticClass:"el-icon-close",on:{"click":_vm.handleRefund}})]),_c('div',{staticClass:"table"},[_c('el-table',{ref:"multipleTable",staticClass:"center_table",staticStyle:{"width":"100%%"},attrs:{"data":_vm.authArr,"tooltip-effect":"dark","border":"","header-cell-style":{
                    width: 81.823 + 'vw',
                    height: 5.185 + 'vh',
                    background: '#F9F9F9',
                    fontSize: 0.729 + 'vw',
                    textAlign: 'center',

                }},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"align":"center","prop":"id","type":"index","label":"序号","min-width":"14.3px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"name","label":"道具名称","min-width":"16.8px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"link","label":"道具链接","min-width":"38.8px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"createtime","label":"创建时间","min-width":"15.7px"}})],1)],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mask-title"},[_c('div'),_vm._v(" 查看道具链接 ")])
}]

export { render, staticRenderFns }