<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 添加订单 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    更换员工
                </div>
                <i class="el-icon-close" @click="handleRefund"></i>
            </div>
            <div class="mask-content">
                <el-select :popper-append-to-body="false" v-model="userobj.photographer_id" placeholder="请选择摄影师"
                    class="selec" clearable>
                    <el-option v-for="item in photographers" :key="item.id" :label="item.realname" :value="item.id">
                    </el-option>
                </el-select>
                <el-select :popper-append-to-body="false" v-model="userobj.makeuper_id" placeholder="请选择妆引师"
                    class="selec" clearable>
                    <el-option v-for="item in makeupers" :key="item.id" :label="item.realname" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="mask-footer">
                <button class="btn-bg" @click="handleSubmit">确定</button>
                <button @click="handleRefund">取消</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MaskPack",
    props: ['data'],
    data() {
        return {
            userobj: {
                id: '',
                photographer_id: '',
                makeuper_id: '',
            },
            photographers: [],
            makeupers: []
        };
    },
    mounted() {

    },
    methods: {
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,
                submitFlag: false
            }
            this.$emit("replacingCall", data);
        },
        handleSubmit() {
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true,//是否调用接口
                userobj: this.userobj
            }
            this.$emit("replacingCall", data);
        },
        //得到摄影师
        async getphotographer() {
            let res = await this.$api.getphotographer();
            this.photographers = res.data;

            this.photographers.forEach(item => {
                if (item.realname == this.data.photographer) {
                    this.userobj.photographer_id = item.id
                }
            })
        },
        //得到妆引师
        async getmakeuper() {
            let res = await this.$api.getmakeuper();
            this.makeupers = res.data;
            this.makeupers.forEach(item => {
                if (item.realname == this.data.makeuper) {
                    this.userobj.makeuper_id = item.id
                }
            })
        },
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                if (newVal.flag) {
                    console.log(newVal, this.photographers, this.makeupers)
                    this.userobj.id = newVal.id
                    // this.userobj.photographer_id = newVal.photographer
                    // this.userobj.makeuper_id = newVal.makeuper
                    this.getphotographer()
                    this.getmakeuper()
                }
            },
            deep: true
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
    .mask-main {
        width: 39.0625vw;
        border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;
    }

    .mask-content {
        display: flex;
        font-size: 0.833vw;
        color: #333333;
        height: 7.407vh;
        margin: 2.407vh 0 1.852vh;

        .selec {
            flex: 1;
            height: 3.628vh;
        }

        .selec:nth-child(1) {
            margin-right: 1.0417vw;
        }

        /deep/.el-select-dropdown {
            top: 49vh !important;
        }

    }

    .mask-footer {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            width: 7.813vw;
            height: 4.259vh;
            border-radius: 0.313vw;
            font-size: 0.729vw;
            cursor: pointer;
        }

        button {
            background: #fff;
            border: 0.052vw solid#066ff8;
            color: #066FF8;
            margin-right: 3.125vw;
        }

        button:last-child {
            margin-right: 0;
        }

        .btn-bg {
            background: #066ff8;
            border: 0.052vw solid#066ff8;
            color: #fff;
        }
    }
}
</style>