import Vue from 'vue'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入vue-router
import router from './router/index'  //刚刚建立的文件夹
import VueRouter from 'vue-router'
import App from './App.vue'
//引入组件
import Component from '@/utils/index.js'

//时间过滤
import FilDate from '@/utils/filter/data'
Vue.prototype.$FilDate = FilDate;

Vue.prototype.imgurl = "https://jiekou.paiyide.cc";
Vue.prototype.uploadimg = "https://jiekou.paiyide.cc/api/store/index/uploadimg"
Vue.prototype.uploadfile = "https://jiekou.paiyide.cc/api/store/index/uploadfile"
if (process.env.NODE_ENV == "development") {
  //开发环境
  console.log('开发环境');
  Vue.prototype.imgurl = "https://test.paiyide.cc";
  Vue.prototype.uploadimg = "https://test.paiyide.cc/api/store/index/uploadimg"
  Vue.prototype.uploadfile = "https://test.paiyide.cc/api/store/index/uploadfile"
} else if (process.env.NODE_ENV == "production") {
  //生产环境
  console.log('生产环境');
  Vue.prototype.imgurl = "https://test.paiyide.cc";
  Vue.prototype.uploadimg = "https://test.paiyide.cc/api/store/index/uploadimg"
  Vue.prototype.uploadfile = "https://test.paiyide.cc/api/store/index/uploadfile"
} else {
  //本地环境
  console.log('本地环境');

}
//引入API
import API from '@/request/api';
Vue.prototype.$api = API;


//使用element-ui
Vue.use(ElementUI);
//使用vue-router
Vue.use(VueRouter)
//全局注册组件
Vue.use(Component)



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
