import { post, get, request } from "./http.js";

//接口案例
export const getCouponDetail = (data) => post("/coupondetail", data);

// 登录接口
const indexLogin = (data) => post("index/login", data);
//生成扫码登录二维码
const indexSetqrcode = (data) => post("index/setqrcode", data);
//首页
const indexIndex = (data) => post("index/index", data);
//上传图片
const uploadimg = (data) => post("index/uploadimg", data);
//上传文件
const uploadfile = (data) => post("index/uploadfile", data);
//登录日志
const indexLoginlog = (data) => post("index/loginlog", data);
//操作日志
const indexOperatelog = (data) => post("index/operatelog", data);
//软件服务费列表
const indexRenewlog = (data) => post("index/renewlog", data);
//软件服务费续费
const indexRenew = (data) => post("index/renew", data);
//软件服务费续费
const indexGetinfo = (data) => post("index/getinfo", data);
// 数据统计看板主界面
const indexDataview = (data) => post("index/dataview", data);
// 点拍_重拍_更换 数据列表
const indexStandarddatalist = (data) => post("index/standarddatalist", data);
// 评价率、好评率数据列表
const indexEvaluatedatalist = (data) => post("index/evaluatedatalist", data);
// 账号设置（账号及店铺信息编辑）
const indexEditinfo = (data) => post("index/editinfo", data);
// 图片转base64
const indexSetimage = (data) => post("index/setimage", data);

//预约单列表
const orderLst = (data) => post("order/lst", data);
//新增编辑预约单
const orderEdit = (data) => post("order/edit", data);
//预约单详情
const orderDetail = (data) => post("order/detail", data);
//拍摄进度 服装列表
const orderProgresslist = (data) => post("order/progresslist", data);
//拍摄进度服装列表
const progresslist = (data) => post("order/progresslist", data);
//单个或批量设置某套服装拍摄完成、未完成
const orderSetfinish = (data) => post("order/setfinish", data);
//设置重拍
const setrenew = (data) => post("order/setrenew", data);
//预约单上传底片
const setdownload = (data) => post("order/setdownload", data);
//批量导入预约单
const orderImport = (data) => post("/order/import", data);
//得到摄影师
const getphotographer = (data) => post("/order/getphotographer", data);
//得到妆引师
const getmakeuper = (data) => post("/order/getmakeuper", data);
//得到拍摄主题
const gettheme = (data) => post("/order/gettheme", data);
//单个、批量推送微信公众号消息给客户
const orderSendmsg = (data) => post("/order/sendmsg", data);
//获取衣码
const orderGetsize = (data) => post("/order/getsize", data);
//服装列表
const orderGetstandard = (data) => post("/order/getstandard", data);
//选衣状态下预约单设置撤回
const orderSetrecall = (data) => post("/order/setrecall", data);
//重选服装
const orderRenewxuan = (data) => post("/order/renewxuan", data);
// 再次推送 对拍摄未完成的订单进行再次推送 重新选衣
const orderRetuisong = (data) => post("/order/retuisong", data);
// 预约单删除
const orderDel = (data) => post("/order/del", data);
// 对接ERP的预约单数据
const orderGeterpdata = (data) => post("/order/geterpdata", data);
// 更换员工
const orderChangeworker = (data) => post("/order/changeworker", data);
// 撤回绑定（解绑手机号所关联的所有openid）
const orderDelbindphone = (data) => post("/order/delbindphone", data);
// 评价（生成评价二维码，只有拍摄完成的预约单才能操作）
const orderSetevaluateqrcode = (data) => post("/order/setevaluateqrcode", data);

//客户管理列表
const userLst = (data) => post("/user/lst", data);
//客户管理编辑信息
const userEdit = (data) => post("user/edit", data);

//标准片列表
const standardLst = (data) => post("standard/lst", data);
//查看详情
const standardDetail = (data) => post("standard/detail", data);
// 新增编辑信息
const standardEdit = (data) => post("standard/edit", data);
// 批量、单个删除
const standardDel = (data) => post("standard/del", data);
// 批量、单个启用 禁用
const standardSetstatus = (data) => post("standard/setstatus", data);
// 得到摄影师
const standardGetphotographer = (data) => post("standard/getphotographer", data);
// 得到妆引师
const standardGetmakeuper = (data) => post("standard/getmakeuper", data);
// 得到设计师
const standardGetdesigner = (data) => post("standard/getdesigner", data);
// 得到拍摄主题
const standardGettheme = (data) => post("standard/gettheme", data);
// 得到适用年龄
const standardGetage = (data) => post("standard/getage", data);
// 得到风格分类
const standardGettype = (data) => post("standard/gettype", data);
// 得到衣码
const standardGetsize = (data) => post("standard/getsize", data);
// 批量移除物资
const standardCancelassociation = (data) => post("standard/cancelassociation", data);
// 批量关联物资
const standardAssociation = (data) => post("standard/association", data);
// 标准片发布到图库
const standardRelease = (data) => post("standard/release", data);

// 物资管理列表
const materialsLst = (data) => post("materials/lst", data);
// 查看详情
const materialsDetail = (data) => post("materials/detail", data);
// 新增物资购买链接
const materialsAddbuyurl = (data) => post("materials/addbuyurl", data);
// 新增编辑信息
const materialsEdit = (data) => post("materials/edit", data);
// 批量移除物资购买链接
const materialsDelbuyurl = (data) => post("materials/delbuyurl", data);
// 批量、单个删除
const materialsDel = (data) => post("materials/del", data);
// 批量、单个启用 禁用
const materialsSetstatus = (data) => post("materials/setstatus", data);
// 得到物资分类
const materialsGetcate = (data) => post("materials/getcate", data);
// 得到库位编号
const materialsGetlocator = (data) => post("materials/getlocator", data);
// 得到一级库位
const materialsGetfirstlocator = (data) => post("materials/getfirstlocator", data);
// 得到二级库位
const materialsGetseclocator = (data) => post("materials/getseclocator", data);
// 物资单个或批量关联
const materialsAssociation = (data) => post("materials/association", data);


// 数据字典参数名列表
const dictionaryParamlist = (data) => post("dictionary/paramlist", data);
// 参数值列表
const dictionaryValuelist = (data) => post("dictionary/valuelist", data);
// 新增编辑参数值信息
const dictionaryEditvalue = (data) => post("dictionary/editvalue", data);
// 批量、单个删除
const dictionaryDel = (data) => post("dictionary/del", data);
// 批量、单个启用 禁用
const dictionarySetstatus = (data) => post("dictionary/setstatus", data);

// 拍摄主题列表
const themeLst = (data) => post("theme/lst", data);
// 新增编辑信息
const themeEdit = (data) => post("theme/edit", data);
// 批量、单个删除theme/del
const themeDel = (data) => post("theme/del", data);
// 批量、单个启用 禁用theme/setstatus
const themeSetstatus = (data) => post("theme/setstatus", data);
// 批量导入theme/import
const themeImport = (data) => post("theme/import", data);

//模特管理列表
const modelerLst = (data) => post("modeler/lst", data);
//新增、编辑信息
const modelerEdit = (data) => post("modeler/edit", data);
//批量、单个删除
const modelerDel = (data) => post("modeler/del", data);
//批量、单个启用 禁用
const modelerSetstatus = (data) => post("modeler/setstatus", data);
//发送协议 微信公众号模板消息
const modelerSendprotocol = (data) => post("modeler/sendprotocol", data);
//模特协议 新增 编辑
const modelerEditprotocol = (data) => post("modeler/editprotocol", data);
//得到模特协议详情
const modelerGetprotocol = (data) => post("modeler/getprotocol", data);
//得到模特协议修改记录
const modelerGetlog = (data) => post("modeler/getlog", data);
//得到服务人员
const modelerGetadmin = (data) => post("modeler/getadmin", data);
//批量移除关联
const materialsCancelassociation = (data) => post("materials/cancelassociation", data);

//评价管理列表
const evaluateLst = (data) => post("evaluate/lst", data);
//送评价通知 只有未评价的订单才能推送
const evaluateSendnotice = (data) => post("evaluate/sendnotice", data);
//填写处理进展
const evaluateSethandle = (data) => post("evaluate/sethandle", data);
//查看某条订单的评价处理记录
const evaluateShowhandle = (data) => post("evaluate/showhandle", data);

//消息管理列表
const msgLst = (data) => post("msg/lst", data);
//新增、编辑信息
const msgEdit = (data) => post("msg/edit", data);
//批量、单个删除
const msgDel = (data) => post("msg/del", data);
//系统消息列表
const msgSystemmsglist = (data) => post("msg/systemmsglist", data);
//标为已读
const msgSetread = (data) => post("msg/setread", data);

//广告管理列表
const adLst = (data) => post("ad/lst", data);
//新增、编辑信息
const adEdit = (data) => post("ad/edit", data);
//批量、单个删除
const adDel = (data) => post("ad/del", data);
//批量、单个启用 禁用
const adSetstatus = (data) => post("ad/setstatus", data);
//得到广告类型
const adGettype = (data) => post("ad/gettype", data);
//判断之前是否存在店面介绍图
const adChecklocation = (data) => post("ad/checklocation", data);

//角色对应权限菜单
const authorizeLst = (data) => post("authorize/lst", data);
//所有的系统权限
const authorizeGetallmenu = (data) => post("authorize/getallmenu", data);
//新增、编辑角色
const authorizeEdit = (data) => post("authorize/edit", data);
//删除角色
const authorizeDel = (data) => post("authorize/del", data);
//给相应角色分配菜单权限
const authorizeSetmenu = (data) => post("authorize/setmenu", data);

//库位管理 列表
const locatorLst = (data) => post("locator/lst", data);
//新增、编辑
const locatorEdit = (data) => post("locator/edit", data);
//批量、单个删除
const locatorDel = (data) => post("locator/del", data);
//批量导入
const locatorImport = (data) => post("locator/import", data);

//子账号管理列表
const adminLst = (data) => post("admin/lst", data);
//新增、编辑信息
const adminEdit = (data) => post("admin/edit", data);
//批量、单个删除
const adminDel = (data) => post("admin/del", data);
//批量、单个启用 禁用
const adminSetstatus = (data) => post("admin/setstatus", data);
//得到可选角色
const adminGetrole = (data) => post("admin/getrole", data);
//重新绑定
const adminRebind = (data) => post("admin/rebind", data);

//得到当前账号所关联的店铺信息
const indexGetstore = (data) => post("index/getstore", data);
//切换店铺登录
const indexChangelogin = (data) => post("index/changelogin", data);

// 图库列表 (名师推荐详情)
const imagegoodsImagelist = (data) => post("imagegoods/imagelist", data);
// 得到图库主题
const imagegoodsImagetheme = (data) => post("imagegoods/imagetheme", data);
// 得到排序方式
const imagegoodsGetorder = (data) => post("imagegoods/getorder", data);
// 得到名师推荐
const imagegoodsTeacherlist = (data) => post("imagegoods/teacherlist", data);
// 图库详情
const imagegoodsImagedetail = (data) => post("imagegoods/imagedetail", data);
// 加入购物车
const imagegoodsAddcart = (data) => post("imagegoods/addcart", data);
// 购物车列表
const imagegoodsCartlist = (data) => post("imagegoods/cartlist", data);
// 购物车删除
const imagegoodsCartdel = (data) => post("imagegoods/cartdel", data);
// 购物车结算、图库单独结算
const imagegoodsPay = (data) => post("imagegoods/pay", data);
// 批量、移入收藏夹 加入收藏 删除收藏
const imagegoodsCollect = (data) => post("imagegoods/collect", data);
// 已买到的图库 订单列表
const imagegoodsImageorderlist = (data) => post("imagegoods/imageorderlist", data);
// 查看道具链接
const imagegoodsProplinklist = (data) => post("imagegoods/proplinklist", data);
// 删除订单
const imagegoodsImageorderdel = (data) => post("imagegoods/imageorderdel", data);
// 我的收藏列表
const imagegoodsCollectlist = (data) => post("imagegoods/collectlist", data);
// 得到衣码
const imagegoodsGetsize = (data) => post("imagegoods/getsize", data);

// 我的图库
const accountImagelist = (data) => post("account/imagelist", data);
// 申请上架
const accountImagesetup = (data) => post("account/imagesetup", data);
// 申请下架
const accountImagesetdown = (data) => post("account/imagesetdown", data);
// 当前商家的名师信息
const accountTeacherinfo = (data) => post("account/teacherinfo", data);
// 充值记录列表
const accountRechargelog = (data) => post("account/rechargelog", data);
// 账户充值
const accountRecharge = (data) => post("account/recharge", data);
// 得到系统设置的充值金额
const accountSetmoneylist = (data) => post("account/setmoneylist", data);
// 撤销审核
const accountCancel = (data) => post("account/cancel", data);

// 意见反馈列表
const complaintLst = (data) => post("complaint/lst", data);
// 填写处理进程
const complaintSethandle = (data) => post("complaint/sethandle", data);
// 查看某条投诉的处理记录
const complaintShowhandle = (data) => post("complaint/showhandle", data);
// 得到投诉类型
const complaintGetcomplainttype = (data) => post("complaint/getcomplainttype", data);

// 品宣管理
// 客户列表
const spreadUserlist = (data) => post("spread/userlist", data);
// 修改客户信息
const spreadUseredit = (data) => post("spread/useredit", data);
// 客户详情
const spreadUserdetail = (data) => post("spread/userdetail", data);
// 客户浏览记录
const spreadViewlog = (data) => post("spread/viewlog", data);
// 某客户的跟踪记录
const spreadShowfollow = (data) => post("spread/showfollow", data);
// 填写跟踪记录
const spreadSetfollow = (data) => post("spread/setfollow", data);
// 首页视频及品牌配置
const spreadSetconfig = (data) => post("spread/setconfig", data);
// 得到首页视频及品牌页面图片
const spreadGetconfig = (data) => post("spread/getconfig", data);
// 所有样片分类列表
const spreadCatelist = (data) => post("spread/catelist", data);
// 样片分类新增编辑
const spreadCateedit = (data) => post("spread/cateedit", data);
// 设置分类启用禁用
const spreadSetcatestatus = (data) => post("spread/setcatestatus", data);
// 分类删除
const spreadDelcate = (data) => post("spread/delcate", data);
// 样片列表
const spreadStylelist = (data) => post("spread/stylelist", data);
// 新增编辑样片
const spreadStyleedit = (data) => post("spread/styleedit", data);
// 引用标准片
const spreadStandardtostyle = (data) => post("spread/standardtostyle", data);
// (批量)删除样片
const spreadStyledel = (data) => post("spread/styledel", data);
// 置顶样片
const spreadStyletop = (data) => post("spread/styletop", data);
// 启用禁用样片
const spreadStylestatus = (data) => post("spread/stylestatus", data);
// 样片详情
const spreadStyledetail = (data) => post("spread/styledetail", data);
// 分类排序
const spreadSetcateorder = (data) => post("spread/setcateorder", data);


// 类型管理主页面数据（得到所有类型）
const typeIndex = (data) => post("type/index", data);
// 获取包含风格数据
const typeGetchild = (data) => post("type/getchild", data);
// 新增编辑
const typeEdit = (data) => post("type/edit", data);
// 批量、单个删除
const typeDel = (data) => post("type/del", data);
// 启用、禁用
const typeSetstatus = (data) => post("type/setstatus", data);
// 详情
const typeDetail = (data) => post("type/detail", data);


export default {
    indexLogin,
    indexSetqrcode,
    indexIndex,
    uploadimg,
    uploadfile,
    indexLoginlog,
    indexOperatelog,
    indexRenewlog,
    indexRenew,
    indexGetinfo,
    indexDataview,
    indexStandarddatalist,
    indexEvaluatedatalist,
    indexEditinfo,
    indexSetimage,

    orderLst,
    orderEdit,
    orderDetail,
    orderProgresslist,
    progresslist,
    orderSetfinish,
    setrenew,
    setdownload,
    orderImport,
    getphotographer,
    getmakeuper,
    gettheme,
    orderSendmsg,
    orderGetsize,
    orderGetstandard,
    orderSetrecall,
    orderRenewxuan,
    orderRetuisong,
    orderDel,
    orderGeterpdata,
    orderChangeworker,
    orderDelbindphone,
    orderSetevaluateqrcode,

    userLst,
    userEdit,

    standardLst,
    standardDetail,
    standardEdit,
    standardDel,
    standardSetstatus,
    standardGetphotographer,
    standardGetmakeuper,
    standardGetdesigner,
    standardGettheme,
    standardGetage,
    standardGettype,
    standardGetsize,
    standardCancelassociation,
    standardAssociation,
    standardRelease,

    materialsLst,
    materialsDetail,
    materialsAddbuyurl,
    materialsEdit,
    materialsDelbuyurl,
    materialsDel,
    materialsSetstatus,
    materialsGetcate,
    materialsGetlocator,
    materialsAssociation,
    materialsGetfirstlocator,
    materialsGetseclocator,

    dictionaryParamlist,
    dictionaryValuelist,
    dictionaryEditvalue,
    dictionaryDel,
    dictionarySetstatus,

    themeLst,
    themeEdit,
    themeDel,
    themeSetstatus,
    themeImport,

    modelerLst,
    modelerEdit,
    modelerDel,
    modelerSetstatus,
    modelerSendprotocol,
    modelerEditprotocol,
    modelerGetprotocol,
    modelerGetlog,
    modelerGetadmin,
    materialsCancelassociation,

    evaluateLst,
    evaluateSendnotice,
    evaluateSethandle,
    evaluateShowhandle,

    msgLst,
    msgEdit,
    msgDel,
    msgSystemmsglist,
    msgSetread,

    adLst,
    adEdit,
    adDel,
    adSetstatus,
    adGettype,
    adChecklocation,

    authorizeLst,
    authorizeGetallmenu,
    authorizeEdit,
    authorizeDel,
    authorizeSetmenu,

    locatorLst,
    locatorEdit,
    locatorDel,
    locatorImport,

    adminLst,
    adminEdit,
    adminDel,
    adminSetstatus,
    adminGetrole,
    adminRebind,

    indexGetstore,
    indexChangelogin,
    imagegoodsImagelist,
    imagegoodsImagetheme,
    imagegoodsGetorder,
    imagegoodsTeacherlist,
    imagegoodsImagedetail,
    imagegoodsAddcart,
    imagegoodsCartlist,
    imagegoodsCartdel,
    imagegoodsPay,
    imagegoodsCollect,
    imagegoodsImageorderlist,
    imagegoodsProplinklist,
    imagegoodsImageorderdel,
    imagegoodsCollectlist,
    imagegoodsGetsize,

    accountImagelist,
    accountImagesetup,
    accountImagesetdown,
    accountTeacherinfo,
    accountRechargelog,
    accountRecharge,
    accountSetmoneylist,
    accountCancel,

    complaintLst,
    complaintSethandle,
    complaintShowhandle,
    complaintGetcomplainttype,

    spreadUserlist,
    spreadUseredit,
    spreadUserdetail,
    spreadViewlog,
    spreadShowfollow,
    spreadSetfollow,
    spreadSetconfig,
    spreadGetconfig,
    spreadCatelist,
    spreadCateedit,
    spreadSetcatestatus,
    spreadDelcate,
    spreadStylelist,
    spreadStyleedit,
    spreadStandardtostyle,
    spreadStyledel,
    spreadStyletop,
    spreadStylestatus,
    spreadStyledetail,
    spreadSetcateorder,

    typeIndex,
    typeGetchild,
    typeEdit,
    typeDel,
    typeSetstatus,
    typeDetail
}