<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 添加订单 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div></div>
                <div class="mask-title">账号设置</div>
                <i class="el-icon-close" @click="handleRefund"></i>
            </div>
            <div class="mask-content">
                <div class="cenbox filei">
                    <div class="cen">
                        <div class="cen_head">
                            <div class="cen_xian"></div>
                            <div class="cen_title">基本资料</div>
                        </div>
                        <div class="cen_body cen-table">
                            <!-- <el-input v-model="userobj.username" placeholder="请输入用户名"></el-input> -->
                            <table>
                                <tr>
                                    <td class="cen-tabtd">登录账号</td>
                                    <td><el-input v-model="userobj.username" placeholder="请输入登录账号"></el-input></td>
                                    <td class="cen-tabtd">联系人姓名</td>
                                    <td><el-input v-model="userobj.name" placeholder="请输入联系人姓名"></el-input></td>
                                </tr>
                                <tr>
                                    <td class="cen-tabtd">联系人电话</td>
                                    <td>
                                        <el-input v-model="userobj.phone" maxlength="11" type="number"
                                            placeholder="请输入联系人电话"></el-input>
                                    </td>
                                    <td class="cen-tabtd">店铺名称</td>
                                    <td><el-input v-model="userobj.shopname" placeholder="请输入店铺名称"></el-input></td>
                                </tr>
                                <tr>
                                    <td class="cen-tabtd">店铺地址</td>
                                    <td><el-input v-model="userobj.shopaddr" placeholder="请输入店铺地址"></el-input></td>
                                    <td class="cen-tabtd">详细地址</td>
                                    <td><el-input v-model="userobj.shopdetailaddr" placeholder="请输入详细地址"></el-input>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="cenbox filei">
                    <div class="cen">
                        <div class="cen_head">
                            <div class="cen_xian"></div>
                            <div class="cen_title">登录密码</div>
                        </div>
                        <div class="cen_body cen-table">
                            <table>
                                <tr>
                                    <td class="cen-tabtd">登录密码</td>
                                    <td><el-input v-model="userobj.password" placeholder="请输入登录密码"></el-input></td>
                                    <td class="cen-tabtd">再次确认密码</td>
                                    <td><el-input v-model="reppassword" placeholder="请输入确认密码"></el-input></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mask-footer">
                <button @click="handleRefund">{{ dataVal.btns }}</button>
                <button class="btn-bg" @click="handleSubmit">{{ dataVal.btnq }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErpIntegration",
    props: ['dataVal'],
    data() {
        return {
            userobj: {
                username: '',
                name: '',
                phone: '',
                shopname: '',
                shopaddr: '',
                shopdetailaddr: '',
                password: '',
            },
            reppassword: '',
        };
    },
    mounted() {
    },
    methods: {
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo()
            if (res.code == 1) {
                this.userobj = res.data
                this.userobj = {
                    username: res.data.username ? res.data.username : '',
                    name: res.data.realname ? res.data.realname : '',
                    phone: res.data.phone ? res.data.phone : '',
                    shopname: res.data.shopname ? res.data.shopname : '',
                    shopaddr: res.data.shopaddr ? res.data.shopaddr : '',
                    shopdetailaddr: res.data.shopdetailaddr ? res.data.shopdetailaddr : '',
                    password: '',
                }
            }
        },
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,
                submitFlag: false
            }
            this.$emit("settingsCall", data);
        },
        handleSubmit() {
            let repuserobj = JSON.parse(JSON.stringify(this.userobj))
            if ((this.userobj.password != '' && this.userobj.password != null) && this.reppassword != this.userobj.password) {
                this.$message.error('登录密码与确认密码不一致');
                return false;
            }
            if (this.userobj.password != '') {
                delete repuserobj.password
            }
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true,//是否调用接口
                obj: repuserobj
            }
            this.$emit("settingsCall", data);
        },
    },
    watch: {
        dataVal: {
            handler(newValue, oldValue) {
                if (newValue.flag) {
                    this.indexGetinfo()
                }
            },
            deep: true
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table2.less");


div {
    box-sizing: border-box;
}

.order-mask {
    .mask-main {
        width: 40vw;
        padding: 0;
        border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;

        .mask-nav {
            height: 2.6042vw;
            padding: 0 .7813vw;
            background-color: #066ff8;

            .mask-title {
                font-weight: 400;
                color: #fff;
            }

            .el-icon-close {
                color: #fff;
                display: flex;
                align-items: center;
            }
        }
    }

    .mask-content {
        width: 100%;
        background-color: #fff;
        padding: 0 .7813vw;

        .cenbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cen {
                flex: 1;

                .cen_head {
                    display: flex;
                    align-items: center;
                    padding: .5208vw 0;

                    .cen_xian {
                        margin-right: 0.521vw;
                        width: 0.208vw;
                        height: 0.833vw;
                        background-color: #2c72ff;
                    }

                    .xin2::after {
                        content: '*';
                        color: red;
                    }
                }

                /deep/.cen_body {
                    width: 100%;
                    // height: 1.8229vw;
                    border: .0521vw solid #d7d7d7;
                    border-radius: .2604vw;
                    display: flex;
                    align-items: center;

                    // table {
                    //     width: 100%;

                    //     tr {
                    //         width: 100%;
                    //         // border-top: .0521vw solid #d7d7d7;

                    //         td {
                    //             color: #333;
                    //             border: 0.052vw solid #e1e1e1;
                    //         }

                    //         .cen-tabtd {
                    //             background-color: #F8F8F8;
                    //         }
                    //     }

                    //     tr:first-child {
                    //         border: 0;
                    //     }
                    // }

                    .el-input {
                        .el-input__inner {
                            border: 0;
                        }
                    }
                }

            }

            .cen:nth-of-type(2) {
                margin-left: .7813vw;
            }
        }
    }

    .mask-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5625vw;
        margin-bottom: .7813vw;

        button {
            width: 7.813vw;
            height: 4.259vh;
            border-radius: 0.313vw;
            font-size: 0.729vw;
            cursor: pointer;
        }

        button {
            background: #fff;
            border: 0.052vw solid #066ff8;
            color: #066FF8;
            margin-right: 3.125vw;
        }

        button:last-child {
            margin-right: 0;
        }

        .btn-bg {
            background: #066ff8;
            border: 0.052vw solid #066ff8;
            color: #fff;
        }
    }
}
</style>