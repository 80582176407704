<template>
  <div class="header">
    <img src="../assets/images/head/logo2.png" class="img1" alt="" />
    <div class="head-l">
      <div class="head-l1" :class="{ 'head-l1act': active == item.id }" v-for="item in routerArr" :key="item.id"
        @click="click(item.id, item.router)">
        {{ item.name }}
      </div>
    </div>
    <!--  -->
    <div class="head-c" v-if="day <= 20" v-cloak>软件服务到期日期：{{ this.after_time }}</div>
    <div class="shopQ" style="margin-left: 1.094vw;">
      <el-dropdown @command="command2" placement="bottom">
        <div class="header-r">
          登录店铺：{{ shopname }}&nbsp;&nbsp;<i class="el-icon-arrow-down"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in shopArr" :key="item.store_id" :command="item.store_id">{{ item.shopname
            }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="head-tongzhi" @click="handleTonotice">
      <img src="../assets/images/icon/tongzhi.png" alt="" />
    </div>
    <div class="header-r">
      <el-dropdown @command="command" placement="bottom" style="margin-right: .7813vw;"
        v-if="accountinfodata.wxbindqrcode != null && accountinfodata.wxbindqrcode != ''">
        <!-- v-if="accountinfodata.wxbindqrcode != null && accountinfodata.wxbindqrcode != ''" -->
        <div class="header-r">
          <img class="wzgzpticonhead" src="../assets/images/icon/wzgzpticon.png" alt="" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="3"> -->
          <div class="dropdowncodeuploadmask">
            <div class="title">
              <div class="xian"></div>顾客关联公众号
            </div>
            <img class="" :src="imgurl + accountinfodata.wxbindqrcode" alt="" />
            <div @click="downloadText(imgurl + accountinfodata.wxbindqrcode, accountinfodata.wxbindqrcode)"
              style="cursor: pointer;">下载</div>
          </div>
          <!-- </el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown @command="command" placement="bottom" style="margin-right: .7813vw;">
        <div class="header-r">
          <img class="wzgzpticonhead" src="../assets/images/icon/wxxcxicon.png" alt="" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="3"> -->
          <div class="dropdowncodeuploadmask">
            <div class="title">
              <div class="xian"></div>工作人员小程序
            </div>
            <img class="" src="../assets/images/icon/gzry.png" alt="" />
            <div @click="downloadText('https://sj.paiyide.cc/img/gzry.f93f706c.png')" style="cursor: pointer;">下载</div>
          </div>
          <!-- </el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown @command="command" placement="bottom">
        <div class="header-r">
          <img src="../assets/images/head/toux.png" class="head-r" alt="" />
          <div>{{ this.useInfo.realname }}</div>
          <img src="../assets/images/head/xiala.png" class="img2" alt="" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">账号设置</el-dropdown-item>
          <el-dropdown-item command="2">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <AccountSettings :dataVal="useinfoObj" @settingsCall="settingsCall"></AccountSettings>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      after_time: "",
      useInfo: {},
      routerArr: [],
      list: [
        {
          id: '1',
          name: "首页",
          router: "/",
          child: ["/", "/standardStatistics", "/appointmentStatistics", "/replacementDetails", "/evaluationRate", "/loginLog", "/operationLog", "/software"],
        },
        {
          id: '2',
          name: "管理中心",
          router: "/management",
          child: ["/reservation", "/appointmentInfo", "/basicInfo", "/clothingInfo", "/calmetrics", "/calmetricsDetail", "/addCalmetrics", "/customer", "/noticeMessage", "/systemMessage", "/advertisement", "/evaluation", "/addEvaluation", "/modelManagement", "/feedback", "/AddFeedback", "/material", "/associationMaterial", "/materialDetail", "/addMaterial"],
        },
        {
          id: '3',
          name: "图库商城",
          router: "/galleryMall",
          child: ["/galleryshop", "/teacherDetail", "/imageDetail", "/shoppingcart", "/purchased", "/mycollection", "/withdrawalapplication", "/account"]
        },
        {
          id: '85',
          name: "品宣管理",
          router: "/customerManagement",
          child: ["/customerManagement", "/addtrackrecord", "/basicInformation", "/browsingHistory", "/trackrecord", "/styleIndex", "/childResearch", "/styleMode", "/styleBrand", "/addingRecord"]
        },
        {
          id: '4',
          name: "系统设置",
          router: "/systemSettings",
          child: ["/authorization", "/subAccount", "/modelAgreement", "/inventory", "/dataDictionary", "/shootingTheme"]
        },
      ],
      active: 0,
      day: 22,
      shopname: "",
      shopArr: [],

      accountinfodata: {}, //  账号信息

      useinfoObj: {
        flag: false,
        data: {},
        btns: '取消',
        btnq: '保存'
      }
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.useInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.indexGetinfo();
      this.indexGetstore()
    }
  },
  mounted() {
    if (this.$route.path == "/") {
      this.active = 1;
      return false;
    }
    if (sessionStorage.getItem("activeId")) {
      this.active = Number(sessionStorage.getItem("activeId"));
    }
    // this.indexGetinfo()
  },
  methods: {
    //点击下载
    async downloadText(urls, name) {

      // // 创建隐藏的可下载链接
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = 'https://jiekou.paiyide.cc/uploads/img/1705298841565740.png';
      // // 保存下来的文件名
      // a.download = name;
      // document.body.appendChild(a);
      // a.click();
      // // 移除元素
      // document.body.removeChild(a);

      window.open(urls)

      // var x = new XMLHttpRequest();
      // x.open("GET", urls, true);
      // x.responseType = 'blob';
      // x.onload = function (e) {
      //   var url = window.URL.createObjectURL(x.response)
      //   var a = document.createElement('a');
      //   a.href = url
      //   a.download = name;
      //   a.click()
      // }
      // x.send();
    },
    //得到当前账号所关联的店铺信息
    async indexGetstore() {
      let res = await this.$api.indexGetstore();
      if (res.code == 1) {
        this.shopArr = res.data;
      }
    },
    //切换店铺
    async command2(command) {
      let res = await this.$api.indexChangelogin({
        store_id: command
      });
      if (res.code == 1) {
        window.localStorage.setItem('token', res.data.token);
        this.active = 0;
        this.$router.push('/')
        this.$forceUpdate();
        this.indexGetinfo();
        this.indexGetstore();

        let arr = [];
        let obj = {
          name: this.$route.name,
          router: this.$route.path,
        }
        arr.push(obj)
        window.sessionStorage.setItem('tag', JSON.stringify(arr))
        window.location.reload();
      }
    },
    handleTonotice() {
      this.$router.push("/noticeMessage");
    },
    async settingsCall(obj) {
      if (obj.submitFlag) {
        let res = await this.$api.indexEditinfo(obj.obj)
        if (res.code == 1) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.useinfoObj.flag = false
        }
      } else {
        this.useinfoObj.flag = false
      }
    },
    click(i, router) {
      sessionStorage.setItem("activeId", i);
      this.active = i;
      this.$router.push(router);
    },
    command(command) {
      if (command == "1") {
        this.useinfoObj.flag = true
      } else if (command == "2") {
        this.handleExit();
      }
    },
    handleExit() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出登录成功!",
          });
          localStorage.clear();
          this.$router.push("/login");
        })
        .catch(() => { });
    },
    async indexGetinfo() {
      let res = await this.$api.indexGetinfo();
      if (res.code == 1) {
        this.accountinfodata = res.data

        let stime = new Date();
        stime = parseInt(stime.getTime() / 1000);
        let etime = res.data.endtime;
        this.after_time = this.$FilDate.filDate(etime * 1000);
        let day = parseInt((etime - stime) / 3600 / 24);
        this.day = day;
        this.shopname = res.data.shopname;
        this.store_id = res.data.store_id;
        // console.log(day);

        localStorage.setItem("role_id", res.data.role_id);
        res.data.menu_ids = (res.data.menu_ids != null && res.data.menu_ids != '') ? res.data.menu_ids.split(",") : ['-1']
        let menuids = JSON.parse(JSON.stringify(res.data.menu_ids));
        localStorage.setItem("menu_ids", JSON.stringify(menuids));
        this.routerArr = this.list.filter(item => {
          return menuids.includes(item.id)
        })
        // console.log(this.routerArr, menuids);

      }
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val.path);
        this.list.forEach(item => {
          if (item.child.includes(val.path)) {
            this.active = item.id
          }
        })
        this.indexGetinfo()
      },
      // 深度观察监听
      deep: true
    }
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 8.148vh;
  background-color: #fff;
  // margin-bottom: 0.926vh;
  display: flex;
  align-items: center;
  box-shadow: 0.37vh 0 0.926vh 0 #f3f2f2;

  .head-l {
    display: flex;
  }

  .head-l1 {
    width: 7.813vw;
    text-align: center;
    font-size: 0.833vw;
    color: #333333;
    cursor: pointer;
    min-height: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .head-l1act {
    color: #2c72ff;
    font-weight: bold;
  }

  .head-c {
    padding: 0.556vh 0.521vw;
    border-radius: 1.563vw;
    color: #ff0000;
    border: 0.052vw solid#ff0000;
    margin-left: 2.604vw;
  }

  .img1 {
    width: 9.635vw;
    height: auto;
    padding: 0 1.042vw;
    box-sizing: border-box;
  }

  .head-r {
    width: 1.979vw;
    height: 1.979vw;
    margin-right: 0.521vw;
  }

  .img2 {
    margin-left: 0.521vw;
    margin-right: 3.646vw;
    width: 0.625vw;
    height: 0.365vw;
  }

  .header-r {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.833vw;
  }
}
</style>
<style lang="less">
.el-dropdown-menu {
  min-width: 7.292vw;
  text-align: center;
}
</style>
<style lang="less">
.head-tongzhi {
  position: absolute;
  right: 20vw;
  cursor: pointer;

  img {
    width: 1.667vw;
    height: 1.667vw;
  }
}

.el-dropdown-menu__item {
  line-height: 3.333vh;
  font-size: 0.729vw;
  padding: 0 1.042vw;
}

.maskimage {
  display: flex;
}

.wzgzpticonhead {
  width: 1.5625vw;
  height: 1.5625vw;
}

.header-r .el-dropdown-menu__item {
  padding: 0;
}

.dropdowncodeuploadmask {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 .5208vw;
    margin: 0 0 .5208vw 0;
    box-sizing: border-box;

    .xian {
      margin-right: 0.521vw;
      width: 0.208vw;
      height: .7813vw;
      background: #066ff8;
      margin-top: 0;
    }
  }

  img {
    width: 5.2083vw;
    height: 5.2083vw;
  }

  div {
    width: 100%;
    text-align: center;
    margin-top: .5208vw;
  }
}
</style>